import React from 'react';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';

const faqs = [
  {
    question: 'Kan ik Homigo eerst proberen?',
    answer:
      'We bieden iedereen een gratis proefversie van 30 dagen aan. Download de app op de app of playstore en begin vandaag nog met bouwen.',
  },
  {
    question: 'Hoeveel projecten kan ik starten?',
    answer:
      'Afhankelijk van welk plan je kiest kan je tussen de 2 en oneindig veel projecten managen.',
  },
  {
    question: 'Wie ziet welke informatie?',
    answer:
      'Je kunt zelf bepalen wie welke informatie binnen de app ziet. Zo houd jij controle over het gehele proces.',
  },
  {
    question: 'Wat kost Homigo?',
    answer: 'Hierboven zie je welke verschillende abonnementen we aanbieden.',
  },
  {
    question: "Hebben jullie ook een koppeling met boekhoudprogramma's?",
    answer: 'We hebben nog geen koppeling met boekhoudprogramma’s',
  },
  // More questions...
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Faq() {
  return (
    <div className="bg-gray-50">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
          <h2 className="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Veelgestelde vragen
          </h2>
          <dl className="mt-6 space-y-6 divide-y divide-gray-200">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                        <span className="font-medium text-gray-900">{faq.question}</span>
                        <span className="ml-6 h-7 flex items-center">
                          <ChevronDownIcon
                            className={classNames(
                              open ? '-rotate-180' : 'rotate-0',
                              'h-6 w-6 transform'
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-gray-500">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
