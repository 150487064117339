import React from 'react';
import Layout from '../components/layout';
import Pricing from '../components/Pricing/Pricing';
import Faq from '../components/Pricing/Faq';
import BookDemo from '../components/BookDemo';
import { SEO } from '../components/SEO/SEO';

const PrijzenPage = ({ location }) => (
  <Layout>
    <SEO location={location} pageMetadata={{ title: 'Prijzen' }} />
    <Pricing />
    <BookDemo />
    <Faq />
  </Layout>
);

export default PrijzenPage;
