import React, { useState } from 'react';
import { CheckIcon } from '@heroicons/react/solid';

const tiers = [
  {
    name: 'Gratis',
    href: 'https://app.homigo.nl/signup?utm_source=website&utm_medium=web_cta&utm_campaign=sign_up',
    priceMonthly: 0,
    priceYearly: 0,
    description: 'Voor de kleine aannemer',
    includedFeatures: [
      'App op je mobiel,',
      'Maximaal 2 projecten',
      'Maximaal 2 leden per project',
      'Chatfunctie',
      'Documenten delen',
      'Planner',
      'Werkbonnen',
      'Meerwerk afspraken',
      'Webversie voor computer/laptop',
    ],
    notIncludedFeatures: ['Urenregistratie', 'Materiaalbriefjes', 'Kostendeclaraties'],
  },
  {
    name: 'Budget',
    href: 'https://app.homigo.nl/signup/professional?budget=true&utm_source=website&utm_medium=web_cta&utm_campaign=sign_up',
    priceMonthly: 9.99,
    priceYearly: 8.99,
    description: 'Voor de ZZPer',
    includedFeatures: [
      'App op je mobiel',
      'Onbeperkt aantal projecten',
      'Maximaal 3 leden per project',
      'Chatfunctie',
      'Documenten delen',
      'Planner',
      'Werkbonnen',
      'Meerwerk afspraken',
      'Webversie voor computer/laptop',
      'Urenregistratie',
      'Materiaalbriefjes',
      'Kostendeclaraties',
    ],
    notIncludedFeatures: [],
  },
  {
    name: 'Pro',
    href: 'https://app.homigo.nl/signup/professional?pro=true&utm_source=website&utm_medium=web_cta&utm_campaign=sign_up',
    priceMonthly: 69.99,
    priceYearly: 59.99,
    description: 'Minder faalkosten, meer omzet',
    includedFeatures: [
      'App op je mobiel',
      'Onbeperkt aantal projecten',
      'Maximaal 6 leden per project',
      'Chatfunctie',
      'Documenten delen',
      'Planner',
      'Werkbonnen',
      'Meerwerk afspraken',
      'Webversie voor computer/laptop',
      'Urenregistratie',
      'Materiaalbriefjes',
      'Kostendeclaraties',
    ],
    notIncludedFeatures: [],
    recommended: true,
  },
  {
    name: 'Expert',
    href: 'https://app.homigo.nl/signup/professional?expert=true&utm_source=website&utm_medium=web_cta&utm_campaign=sign_up',
    priceMonthly: 99.99,
    priceYearly: 79.99,
    description: 'Voor de grotere bouwbedrijven',
    includedFeatures: [
      'App op je mobiel',
      'Onbeperkt aantal projecten',
      'Onbeperkt aantal leden per project',
      'Chatfunctie',
      'Documenten delen',
      'Planner',
      'Werkbonnen',
      'Meerwerk afspraken',
      'Webversie voor computer/laptop',
      'Urenregistratie',
      'Materiaalbriefjes',
      'Kostendeclaraties',
    ],
    notIncludedFeatures: [],
  },
];

export default function Pricing() {
  const [pricePeriod, setPricePeriod] = useState('YEARLY');

  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:flex-col sm:align-center">
          <h1 className="text-5xl font-extrabold text-gray-900 sm:text-center">
            Kies het juiste plan voor jouw bedrijf.
          </h1>
          <p className="mt-5 text-xl text-gray-500 sm:text-center">
            We hebben verschillende mogelijkheden afhankelijk van de grootte van je bedrijf.
          </p>
          <div className="relative self-center mt-6 bg-gray-100 rounded-lg p-0.5 flex sm:mt-8">
            <button
              onClick={() => setPricePeriod('MONTHLY')}
              type="button"
              className={`relative w-1/2  border-gray-200 rounded-md  py-2 text-sm font-medium text-gray-900 whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-yellow-400 focus:z-10 sm:w-auto sm:px-8
              ${pricePeriod === 'MONTHLY' ? 'bg-white shadow-sm' : ''}`}
            >
              Maandelijks
            </button>
            <button
              onClick={() => setPricePeriod('YEARLY')}
              type="button"
              className={`relative w-1/2  border-gray-200 rounded-md  py-2 text-sm font-medium text-gray-900 whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-yellow-400 focus:z-10 sm:w-auto sm:px-8
              ${pricePeriod === 'YEARLY' ? 'bg-white shadow-sm' : ''}`}
            >
              Jaarlijks
            </button>
          </div>
        </div>
        <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-4">
          {tiers.map((tier) => (
            <div
              key={tier.name}
              className={`rounded-lg shadow-sm divide-y divide-gray-200  ${
                tier.recommended ? 'border-yellow-400 border-2' : 'border border-gray-200'
              }`}
            >
              <div className="p-6">
                <div className="flex justify-between">
                  <h2 className="text-lg leading-6 font-medium text-gray-900">{tier.name}</h2>
                  {tier.recommended ? (
                    <div className="bg-secondary text-white h-8 px-2 py-2 -m-10 rounded-md">
                      <p className="text-xs">Aannemer’s eerste keuze</p>
                    </div>
                  ) : (
                    ''
                  )}
                </div>

                <p className="mt-4 text-sm text-gray-500">{tier.description}</p>
                <p className="mt-8">
                  <span className="text-4xl font-extrabold text-gray-900">
                    {pricePeriod === 'MONTHLY'
                      ? new Intl.NumberFormat('nl-NL', {
                          style: 'currency',
                          currency: 'EUR',
                        }).format(tier.priceMonthly)
                      : new Intl.NumberFormat('nl-NL', {
                          style: 'currency',
                          currency: 'EUR',
                        }).format(tier.priceYearly)}
                  </span>
                  <span className="text-base font-medium text-gray-500">/per maand</span>
                </p>
                {pricePeriod === 'YEARLY' && tier.priceMonthly > 0 && (
                  <p className="text-md font-medium text-gray-600 line-through">
                    {new Intl.NumberFormat('nl-NL', {
                      style: 'currency',
                      currency: 'EUR',
                    }).format(tier.priceMonthly)}
                  </p>
                )}
                {(pricePeriod === 'MONTHLY' ||
                  (pricePeriod === 'YEARLY' && tier.priceMonthly === 0)) && (
                  <p className="text-md font-extrabold text-gray-900">&nbsp;</p>
                )}

                <a
                  href={tier.href}
                  className="mt-8 block w-full bg-primary border border-yellow-400 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-yellow-500"
                >
                  Probeer nu {tier.name}
                </a>
              </div>
              <div className="pt-6 pb-8 px-6">
                <ul className="mt-2 space-y-4">
                  {tier.includedFeatures.map((feature) => (
                    <li key={feature} className="flex space-x-3">
                      <CheckIcon
                        className="flex-shrink-0 h-5 w-5 text-yellow-400"
                        aria-hidden="true"
                      />
                      <span className="text-sm text-gray-500">{feature}</span>
                    </li>
                  ))}
                  {tier.notIncludedFeatures.map((feature) => (
                    <li key={feature} className="flex space-x-3">
                      <CheckIcon
                        className="flex-shrink-0 h-5 w-5 text-gray-300"
                        aria-hidden="true"
                      />
                      <span className="text-sm text-gray-300">{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
